import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureListing, ensureUser } from '../../../../util/data';
import { getIsClient, getIsConsultant } from '../../../../util/user';
import { createSlug } from '../../../../util/urlHelpers';

import { priceData, handleSubmit } from '../../../ListingPage/ListingPage.shared';

import { LineDivider, Button, NamedLink } from '../../../../components';
import AuthorCard from '../../../../components/AuthorCard/AuthorCard';
import Chip from '../../../../components/Chip/Chip';
import ClockIcon from './icons/ClockIcon';
import PaymentIcon from './icons/PaymentIcon';
import StatisticIcon from './icons/StatisticIcon';
import WalletIcon from './icons/WalletIcon';
import CalendarIcon from './icons/CalendarIcon';

import css from './SearchCard.module.css';

export const SearchCardComponent = props => {
  const { className, rootClassName, intl, listing, config, currentUser } = props;

  const currentListing = ensureListing(listing);
  const {
    title = '',
    description = '',
    price = '',
    publicData,
    createdAt,
  } = currentListing.attributes;

  const slug = createSlug(title);
  const id = currentListing.id.uuid;
  const author = ensureUser(currentListing.author);

  const userAndListingAuthorAvailable = !!(currentUser && author);
  const isOwnListing = userAndListingAuthorAvailable && author.id.uuid === currentUser.id.uuid;

  const { formattedPrice } = priceData(price, config.currency, intl);

  const authorIsClient = getIsClient(author);
  const authorIsConsultant = getIsConsultant(author);

  const currentUserIsClient = getIsClient(currentUser);
  const currentUserIsConsultant = getIsConsultant(currentUser);

  const duration = publicData?.duration;
  const countMilestones = publicData?.milestones?.length;

  return (
    <div className={css.container}>
      <AuthorCard
        author={author}
        postedDate={createdAt}
        intl={intl}
        isSearchPage={true}
        authorIsClient={authorIsClient}
        authorIsConsultant={authorIsConsultant}
      />
      <LineDivider />

      <p className={css.name}>{title}</p>

      <p className={css.description}>{description}</p>

      <LineDivider />

      <p className={css.name}>
        <FormattedMessage id="SearchCard.aboutGig" />
      </p>

      <div className={css.row} style={{ gap: 60 }}>
        {authorIsConsultant && (
          <div className={css.row}>
            <PaymentIcon />
            <p className={css.infoTitle}>
              <FormattedMessage id="SearchCard.hourly" />
            </p>
            <div className={css.bar} />

            <p className={css.info}>{formattedPrice}</p>
          </div>
        )}

        {authorIsClient && (
          <div className={css.row}>
            <WalletIcon />

            <p className={css.infoTitle}>
              <FormattedMessage id="SearchCard.budget" />
            </p>

            <div className={css.bar} />

            <p className={css.info}>{formattedPrice}</p>
          </div>
        )}
        {authorIsConsultant && countMilestones > 0 && (
          <div className={css.row}>
            <StatisticIcon />
            <p className={css.infoTitle}>
              <FormattedMessage id="SearchCard.milestones" />
            </p>
            <div className={css.bar} />
            <p className={css.info}>{countMilestones}</p>
          </div>
        )}

        <div className={css.row}>
          <CalendarIcon />
          <p className={css.infoTitle}>
            <FormattedMessage id="SearchCard.duration" />
          </p>
          <div className={css.bar} />
          <p className={css.info}>
            <FormattedMessage id={'gigDurations.' + duration} />
          </p>
        </div>
      </div>

      <div className={css.skillsContainer}>
        {publicData?.skills?.map((value, index) => {
          return <Chip value={value} key={index} />;
        })}
      </div>

      <NamedLink className={css.link} name="ListingPage" params={{ id, slug }}>
        <FormattedMessage id="SearchCard.viewGigDetails" />
      </NamedLink>

      {!isOwnListing && (
        <>
          <LineDivider />
          <div className={css.buttonsContainer}>
            <Button
              className={css.messageButton}
              onClick={e => {
                if (handleSubmit) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
            >
              <FormattedMessage id="SearchCard.message" />
            </Button>
            {currentUserIsConsultant && (
              <Button
                className={css.button}
                onClick={e => {
                  if (handleSubmit) {
                    e.stopPropagation();
                    e.preventDefault();

                    window.location = '/b/' + currentListing.id.uuid;
                  }
                }}
              >
                <FormattedMessage id="SearchCard.bidForGig" />
              </Button>
            )}

            {/* {currentUserIsClient && (
              <Button
                className={css.button}
                onClick={e => {
                  if (handleSubmit) {
                    e.stopPropagation();
                    e.preventDefault();

                    window.location = '/b/' + currentListing.id.uuid;
                  }
                }}
              >
                <FormattedMessage id="SearchCard.purchaseGig" />
              </Button>
            )} */}
          </div>
        </>
      )}
    </div>
  );
};

SearchCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
};

export default injectIntl(SearchCardComponent);
